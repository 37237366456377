import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import SEO from '../components/seo'
import BrevoConversationsWidget from '../components/chat'
import FreeProductOffer from '../components/FreeProductOffer'
import Share from '../components/share'

const VerbTemplate = ({data, location}) => {
  const verb = data.verbsJson
  const {href} = location

  const getConjugationValue = (conjugation, pronoun) => {
    const sanitizedPronoun = pronoun
      .replace('/', '_')
      .replace("'", '')
      .toLowerCase()

    if (conjugation.tense.toLowerCase() === 'impératif') {
      return conjugation.conjugation[sanitizedPronoun] || ''
    }

    return conjugation.conjugation[sanitizedPronoun]
  }

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const filteredConjugations = verb.conjugations.filter(
    conjugation =>
      ![
        'subjonctif',
        'subjonctif présent',
        'subjonctif passé',
        'subjonctif imparfait',
        'subjonctif plus-que-parfait',
      ].includes(conjugation.tense.toLowerCase()),
  )

  const secondTableConjugations = verb.conjugations.filter(conjugation =>
    [
      'subjonctif',
      'subjonctif présent',
      'subjonctif passé',
      'subjonctif imparfait',
      'subjonctif plus-que-parfait',
    ].includes(conjugation.tense.toLowerCase()),
  )

  const renderConjugationListItem = (conjugation, pronoun, label) => {
    const value = getConjugationValue(conjugation, pronoun)
    if (!value) return null
    return (
      <li>
        <strong>{label}:</strong> {value}
      </li>
    )
  }

  const renderConjugationJe = (conjugation, pronoun, label) => {
    const value =
      getConjugationValue(conjugation, pronoun) ||
      getConjugationValue(conjugation, 'j_')
    if (!value) return null
    return (
      <li>
        <strong>{label}:</strong> {value}
      </li>
    )
  }

  const renderConjugationQueJe = conjugation => {
    const value =
      getConjugationValue(conjugation, 'que_je') ||
      getConjugationValue(conjugation, 'que_j_')
    if (!value) return null
    return (
      <li>
        <strong>Que je/j':</strong> {value}
      </li>
    )
  }

  return (
    <Layout>
      <SEO
        title={verb.seo.title}
        description={verb.seo.description}
        hasJsonLd
        article
      />
      <section style={{marginTop: '4rem'}} className="section">
        <div className="container" style={{marginTop: 25}}>
          <Share redirect={href} title={verb.seo.title} isVertical />
          <div>
            <Link
              style={{margin: '8px auto', order: 0}}
              className="button is-medium"
              to="../"
            >
              <span style={{marginRight: '8px'}}>🔙</span> Volver a los verbos
            </Link>
          </div>
          <h1
            style={{order: 1}}
            className="title is-size-1-mobile has-text-centered"
          >
            {capitalizeFirstLetter(verb.infinitive)}
          </h1>
          <div className="box">
            <h2 className="subtitle">Significado</h2>
            <p>{verb.meaning}</p>
          </div>
          <div className="box">
            <h2 className="subtitle">Uso</h2>
            <p>{verb.usage}</p>
          </div>
          <div className="box">
            <h2 className="subtitle">Ejemplos</h2>
            {verb.examples.map((el, index) => (
              <p key={index}>{el}</p>
            ))}
          </div>
          <div className="box">
            <h2 className="subtitle">Tabla de conjugación</h2>
            <div className="table-container desktop-only">
              <table className="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th>Tiempo</th>
                    <th>Je/J'</th>
                    <th>Tu</th>
                    <th>Il/elle/on</th>
                    <th>Nous</th>
                    <th>Vous</th>
                    <th>Ils/elles</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredConjugations.map(conjugation => (
                    <tr key={conjugation.tense}>
                      <td>{conjugation.tense}</td>
                      <td>
                        {getConjugationValue(conjugation, 'je') ||
                          getConjugationValue(conjugation, 'j_')}
                      </td>
                      <td>{getConjugationValue(conjugation, 'tu')}</td>
                      <td>{getConjugationValue(conjugation, 'il_elle_on')}</td>
                      <td>{getConjugationValue(conjugation, 'nous')}</td>
                      <td>{getConjugationValue(conjugation, 'vous')}</td>
                      <td>{getConjugationValue(conjugation, 'ils_elles')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="table-container desktop-only">
              <table className="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th>Tiempo</th>
                    <th>Que je/j'</th>
                    <th>Que tu</th>
                    <th>Qu'il/elle/on</th>
                    <th>Que nous</th>
                    <th>Que vous</th>
                    <th>Qu'ils/elles</th>
                  </tr>
                </thead>
                <tbody>
                  {secondTableConjugations.map(conjugation => (
                    <tr key={conjugation.tense}>
                      <td>{conjugation.tense}</td>
                      <td>
                        {getConjugationValue(conjugation, 'que_je') ||
                          getConjugationValue(conjugation, 'que_j_')}
                      </td>
                      <td>{getConjugationValue(conjugation, 'que_tu')}</td>
                      <td>
                        {getConjugationValue(conjugation, 'qu_il_elle_on')}
                      </td>
                      <td>{getConjugationValue(conjugation, 'que_nous')}</td>
                      <td>{getConjugationValue(conjugation, 'que_vous')}</td>
                      <td>
                        {getConjugationValue(conjugation, 'qu_ils_elles')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="conjugation-list mobile-only">
              {verb.conjugations.map(conjugation => (
                <div key={conjugation.tense} className="conjugation-list-item">
                  <h3>{conjugation.tense}</h3>
                  <ul>
                    {renderConjugationJe(conjugation, 'je', 'Je/J')}
                    {renderConjugationListItem(conjugation, 'tu', 'Tu')}
                    {renderConjugationListItem(
                      conjugation,
                      'il_elle_on',
                      'Il/elle/on',
                    )}
                    {renderConjugationListItem(conjugation, 'nous', 'Nous')}
                    {renderConjugationListItem(conjugation, 'vous', 'Vous')}
                    {renderConjugationListItem(
                      conjugation,
                      'ils_elles',
                      'Ils/elles',
                    )}
                    {renderConjugationQueJe(conjugation)}
                    {renderConjugationListItem(conjugation, 'que_tu', 'Que tu')}
                    {renderConjugationListItem(
                      conjugation,
                      'qu_il_elle_on',
                      "Qu'il/elle/on",
                    )}
                    {renderConjugationListItem(
                      conjugation,
                      'que_nous',
                      'Que nous',
                    )}
                    {renderConjugationListItem(
                      conjugation,
                      'que_vous',
                      'Que vous',
                    )}
                    {renderConjugationListItem(
                      conjugation,
                      'qu_ils_elles',
                      "Qu'ils/elles",
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <div className="wrapper box">
            <FreeProductOffer
              description={`Recibe cada semana una colección de sugerencias de aprendizaje para seguir adelante en tu progreso.`}
              image={data.FreeProductImage.childImageSharp.fluid}
              dataTarget="FreeProductModalBlog"
              modalId="FreeProductModalBlog"
              formSrc="https://e7b4fa4e.sibforms.com/serve/MUIFAFh-RDlH4kZiUa4IpWxsDizFvims_CjQS5RNoAGLiPE8Fr4OSrX-sx8BdgW6BLTcZzdRuJCRARxXxgl-Q_dkIliCe6O53Hg5kGSxrG81-lgMKyV8lj_xB200-5jxYIvCK5hnxT0r6vi-bAOfHcAHWZ4l_KrT4f31lF1Cmd8IcK8s8-hEoJnFv5NPWIUEFwu8q1H49IeqpX3P"
            />
          </div>
          <Share redirect={href} title={verb.seo.title} />
          <div className="notification is-white has-text-black has-text-centered">
            <strong>
              Cómo podemos mejorar esta página para ti, háznoslo saber usando el
              chat.
            </strong>
          </div>
        </div>
      </section>
      <BrevoConversationsWidget />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    verbsJson(slug: {eq: $slug}) {
      seo {
        title
        description
      }
      infinitive
      conjugations {
        tense
        conjugation {
          je
          j_
          tu
          il_elle_on
          nous
          vous
          ils_elles

          que_je
          que_j_
          que_tu
          qu_il_elle_on
          que_nous
          que_vous
          qu_ils_elles
        }
      }
      meaning
      usage
      examples
    }
    FreeProductImage: file(name: {eq: "FreeProductImageVerb"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default VerbTemplate
