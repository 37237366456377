import React, {useEffect} from 'react'

const BrevoConversationsWidget = () => {
  useEffect(() => {
    ;(function (d, w, c) {
      w.BrevoConversationsID = '6520401027a37730b63b2809'
      w[c] =
        w[c] ||
        function () {
          ;(w[c].q = w[c].q || []).push(arguments)
        }
      var s = d.createElement('script')
      s.async = true
      s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js'
      if (d.head) d.head.appendChild(s)
    })(document, window, 'BrevoConversations')
  }, [])

  return <div>{/* You can add any additional content or styling here */}</div>
}

export default BrevoConversationsWidget
